// @flow
import * as React from "react";
import "./Footer.css";
import { TiSocialInstagram } from "@react-icons/all-files/ti/TiSocialInstagram";
import { RiLinkedinBoxFill } from "@react-icons/all-files/ri/RiLinkedinBoxFill";

export const Footer = () => {
  return (
    <footer>
      <p className={"noselect"}>&copy; Michal Dang 2023</p>
      <p>CVR: 40 45 38 30</p>
      <div className={"socialIcons"}>
        <a
          href={"https://www.instagram.com/bymichaldang/"}
          aria-label={"instagram link"}
        >
          <TiSocialInstagram />
        </a>
        <a
          href={"https://www.linkedin.com/in/michaldang/"}
          aria-label={"linkedin link"}
        >
          <RiLinkedinBoxFill />
        </a>
      </div>
    </footer>
  );
};
