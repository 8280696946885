import React, { useState, useEffect } from "react";
import Portraits from "../../assets/photos/portrait.webp";
import Events from "../../assets/photos/events.webp";
import Lifestyle from "../../assets/photos/lifestyleGallery.webp";
import { motion } from "framer-motion";
import useMediaQuery from "../../utils/responsiveHook";
import MobileScrollDown from "../../components/MobileScrollDown/MobileScrollDown";
import { Link } from "react-router-dom";

interface CarouselImage {
  text: string;
  image: string;
  id: number;
  url: string;
}

const CoverCarousel = () => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const images = [
    { text: "Lifestyle", image: Lifestyle, id: 0, url: "/lifestyle" },
    { text: "Portrait", image: Portraits, id: 1, url: "/portraits" },
    { text: "Event", image: Events, id: 2, url: "/events" },
  ];

  const [currentImage, setCurrentImage] = useState<CarouselImage>(images[0]);

  useEffect(() => {
    function updateToNextImage() {
      setCurrentImage((currentImage) => images[(currentImage.id + 1) % 3]);
    }

    const images = [
      { text: "Lifestyle", image: Lifestyle, id: 0, url: "/lifestyle" },
      { text: "Portrait", image: Portraits, id: 1, url: "/portraits" },
      { text: "Event", image: Events, id: 2, url: "/events" },
    ];

    const interval = setInterval(() => {
      updateToNextImage();
    }, 4000);
    return () => clearInterval(interval);
  }, [currentImage]);

  return (
    <div className={"coverImage"}>
      <div className={"coverImageContent"}>
        <Link to={currentImage.url}>
          <motion.h1
            key={currentImage.text}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {currentImage.text}
          </motion.h1>
        </Link>
        <p>Photographer</p>
        <p>based in Copenhagen</p>
      </div>
      <img src={currentImage.image} alt={"lifestyle cover"} />
      {isMobile && <MobileScrollDown />}
      <div className={"carouselControls"}>
        <div
          className={"carouselIndicator"}
          onClick={() => setCurrentImage(images[0])}
        >
          {currentImage.id === 0 && (
            <motion.div
              className={"activeCarousel"}
              layoutId={"activeImageCarousel"}
            />
          )}
        </div>
        <div
          className={"carouselIndicator"}
          onClick={() => setCurrentImage(images[1])}
        >
          {currentImage.id === 1 && (
            <motion.div
              className={"activeCarousel"}
              layoutId={"activeImageCarousel"}
            />
          )}
        </div>
        <div
          className={"carouselIndicator"}
          onClick={() => setCurrentImage(images[2])}
        >
          {currentImage.id === 2 && (
            <motion.div
              className={"activeCarousel"}
              layoutId={"activeImageCarousel"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CoverCarousel;
