// @flow
import * as React from "react";
import "./FrontPage.css";
import Lifestyle from "../../assets/photos/menuLifestyle.webp";
import Portraits from "../../assets/photos/menuPortrait.webp";
import Events from "../../assets/photos/menuEvent.webp";
import Me from "../../assets/photos/me.webp";
import Aia from "../../assets/aia.webp";
import Klickbait from "../../assets/klickbait.webp";
import Rafn from "../../assets/rafn.webp";
import ActSocial from "../../assets/actsocial.webp";
import PortfolioLink from "./PortfolioLink";
import CoverCarousel from "./CoverCarousel";
import { motion } from "framer-motion";
import { BiDirections } from "@react-icons/all-files/bi/BiDirections";
import { BiCalendarHeart } from "@react-icons/all-files/bi/BiCalendarHeart";
import { FiAperture } from "@react-icons/all-files/fi/FiAperture";
import { useContactUpdate } from "../../utils/contactProvider";

export const FrontPage = () => {
  const setContactDisplay: any = useContactUpdate();
  return (
    <div className={"frontPage"}>
      <CoverCarousel />
      <div className={"portfolioFrontPage"}>
        <h2>Portfolio</h2>
        <div className={"portfolioLinksWrapper"}>
          <PortfolioLink
            image={Lifestyle}
            text={"Lifestyle"}
            link={"/lifestyle"}
          />
          <PortfolioLink
            image={Portraits}
            text={"Portraits"}
            link={"/portraits"}
          />
          <PortfolioLink image={Events} text={"Events"} link={"/events"} />
        </div>
      </div>
      <div className={"portfolioFrontPage serviceWrapper"}>
        <div className={"services"}>
          <motion.div
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
          >
            <BiCalendarHeart />
            <h3>Authentic</h3>
            <p>
              Capturing your identity in the most authentic way to let your
              branding and values speak through photographs.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
          >
            <FiAperture />
            <h3>Timeless</h3>
            <p>
              I always strive to create content that is aesthetic for years to
              come.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
          >
            <BiDirections />
            <h3>Flexible</h3>
            <p>
              Do you need your photos to be delivered early or require special
              equipment? There is always a way to make your wishes come true.
            </p>
          </motion.div>
        </div>
      </div>
      <div className={"aboutMeSectionWrapper"}>
        <div className={"aboutMeSectionWrapperLarge"}>
          <h1>About me</h1>
          <p>
            I am a {new Date().getFullYear() - 1999} years old photographer
            based in Copenhagen. I have lived here since 2018, being born and
            raised in Brno, Czech Republic. Ever since I was a teenager, I have
            been carrying my camera nearly daily and the passion only grew
            stronger. Apart from shooting commercial digital photos, I am mainly
            using film as my go-to medium for my personal work. I work the best
            with atmospheric scenes, whether it is a festival or a cafe with
            sunlight coming in through a window, in combination with creative
            freedom to create eye-catching content.
          </p>
          <p>
            By the way, I am also a full-stack developer. If you have a need for
            a website created exactly to fit your needs, contact me and I can
            help you achieve your vision using a modern technological stack.
          </p>
          <button
            className={"bookingButton"}
            aria-label={"booking"}
            onClick={() => setContactDisplay((state: any) => !state)}
          >
            <h2>Contact</h2>
          </button>
        </div>
        <div className={"aboutMeSectionWrapperSmall"}>
          <img src={Me} alt={"Self-portrait"} />
        </div>
      </div>
      <div className={"portfolioFrontPage clientSection"}>
        <h2>Clients</h2>
        <div className={"clientWrapper"}>
          <motion.a
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
            href={"https://www.aiasoundfestival.dk/"}
          >
            <img src={Aia} alt={"Aiasound"} />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
            href={"https://www.actsocial.dk/pages/aiasocial"}
          >
            <img src={ActSocial} alt={"Act social"} />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
            href={"https://www.instagram.com/rafnfpv/"}
          >
            <img src={Rafn} alt={"Rafn FPV"} />
          </motion.a>
          <motion.a
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
            href={"https://www.klickbait.me/"}
          >
            <img src={Klickbait} alt={"Klickbait"} />
          </motion.a>
        </div>
      </div>
    </div>
  );
};
