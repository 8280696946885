import React from "react";
import "./App.css";
import Contact from "./components/Contact/Contact";
import { Navbar } from "./components/Navbar/Navbar";
import { FrontPage } from "./pages/FrontPage/FrontPage";
import { Footer } from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from "./pages/Gallery/Gallery";
import PhotoDetail from "./pages/PhotoDetail/PhotoDetail";
import { ContactProvider } from "./utils/contactProvider";

function App() {
  return (
    <BrowserRouter>
      <ContactProvider>
        <div className={"app"}>
          <Navbar />
          <Routes>
            <Route path={"/"} element={<FrontPage />} />
            <Route path={"/lifestyle"} element={<Gallery />} />
            <Route path={"/portraits"} element={<Gallery />} />
            <Route path={"/events"} element={<Gallery />} />
            <Route path={"/photo/:id"} element={<PhotoDetail />} />
          </Routes>
          <Contact />
          <Footer />
        </div>
      </ContactProvider>
    </BrowserRouter>
  );
}

export default App;
