import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { Formik, Form, Field, ErrorMessage } from "formik";
import useMediaQuery from "../../utils/responsiveHook";
import EmailWrapper from "./EmailWrapper";
import { useForm, ValidationError } from '@formspree/react';


interface FormValues {
  email: string;
  name: string;
  message: string;
}

const ContactForm = ({ closeModal }: any) => {
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const [messageAlreadySent, setMessageSend] = useState<boolean>(false);
  const [state, handleSubmit] = useForm("xyyryygq");

  const popupVariant = {
    hidden: {
      right: isMobile ? "-100vw" : "-700px",
    },
    visible: {
      right: 0,
    },
  };

  useEffect(() => {
    if(state.succeeded){
      setMessageSend(true);
    }
  }, [state.succeeded]);

  return (
    <motion.div
      className={
        isMobile ? "contactWrapper contactWrapperMobile" : "contactWrapper"
      }
      variants={popupVariant}
      animate={"visible"}
      initial={"hidden"}
      exit={"hidden"}
    >
      <button
        className={"closeContact"}
        aria-label={"closeContact"}
        onClick={() => {
          closeModal();
        }}
      >
        <MdClose />
      </button>
      <h3>Let me know about your next project!</h3>
      {!messageAlreadySent && (
        <Formik
          initialValues={{ email: "", name: "", message: "" }}
          validate={(values: FormValues) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.name) {
              errors.name = "Required";
            } else if (values.name.length > 64) {
              errors.name = "Name too long, 64 characters allowed";
            }

            if (!values.message) {
              errors.message = "Required";
            }

            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className={"formInputWrapper"}>
                <h4>Name</h4>
                <Field type="name" name="name" />
                <div className={"errorDivContainer"}>
                  <p>
                    <ErrorMessage name="name" component="div" />
                    <ValidationError
                        prefix="Name"
                        field="name"
                        errors={state.errors}
                    />
                  </p>
                </div>
              </div>
              <div className={"formInputWrapper"}>
                <h4>Email</h4>
                <Field type="email" name="email" />
                <div className={"errorDivContainer"}>
                  <p>
                    <ErrorMessage name="email" component="div" />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                  </p>
                </div>
              </div>
              <div className={"formInputWrapper"}>
                <h4>Message</h4>
                <Field type="textarea" name="message" component={"textarea"} />
                <div className={"errorDivContainer"}>
                  <p>
                    <ErrorMessage name="message" component="div" />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                  </p>
                </div>
              </div>
              <button type="submit" disabled={state.submitting} name={"sendEmail"}>
                Send
              </button>
            </Form>
          )}
        </Formik>
      )}
      {messageAlreadySent && (
        <div className={"messageSent"}>
          <p>
            Thank you for contacting me. I will get back to you as soon as
            possible. You should also receive a confirmation into your email
            inbox.
          </p>
        </div>
      )}
      <EmailWrapper />
    </motion.div>
  );
};

export default ContactForm;
