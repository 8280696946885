import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { AnimatePresence, motion } from "framer-motion";
import Sunset from "../../assets/photos/sunset.webp";

const MenuMobile = () => {
  const [modalShown, showModal] = useState<boolean>(false);
  const url = useLocation();

  function toggleModal() {
    showModal((state) => !state);
  }

  const menuVariant = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  useEffect(() => {
    showModal(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [url]);

  return (
    <>
      <nav className={"navbarWrapperMobile"}>
        <Link to={"/"}>
          <h1>MICHAL DANG</h1>
        </Link>
        <div className={"burgerWrapper"} onClick={() => toggleModal()}>
          {modalShown ? <MdClose color={"white"} /> : <MdMenu />}
        </div>
      </nav>
      <AnimatePresence>
        {modalShown && (
          <motion.div
            className={"mobileMenuModal"}
            variants={menuVariant}
            animate={"animate"}
            initial={"initial"}
            exit={"exit"}
          >
            <div className={"menuImageCoverMobile"}>
              <img src={Sunset} alt={"menu background"} />
            </div>

            <ul className={"menuMobile"}>
              <li>
                <Link to={"/lifestyle"}>
                  <p>Lifestyle</p>
                </Link>
              </li>
              <li>
                <Link to={"/portraits"}>
                  <p>Portraits</p>
                </Link>
              </li>
              <li>
                <Link to={"/events"}>
                  <p>Events</p>
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MenuMobile;
