import { motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./GalleryImage.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GalleryImage = ({ image }: any) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const BASE_URL = process.env.REACT_APP_PHOTO_BUCKET;
  return (
    <motion.div
      className={"galleryImage"}
      initial={{ opacity: 0, transform: "scale(0.95)" }}
      whileInView={{ opacity: 1, transform: "scale(1)" }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link to={"/photo/" + image.id}>
        <motion.div
          animate={
            isHovered ? { transform: "scale(1.03)" } : { transform: "scale(1)" }
          }
        >
          <LazyLoadImage
            src={`${BASE_URL}/small/${image.filename}.webp`}
            alt={image.meta}
          />
        </motion.div>
      </Link>
    </motion.div>
  );
};

export default GalleryImage;
