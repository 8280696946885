import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "./responsiveHook";
//@ts-ignore
const ContactContext = React.createContext();

//@ts-ignore
const ContactUpdateContext = React.createContext();

export function useContact() {
  return useContext(ContactContext);
}

export function useContactUpdate() {
  return useContext(ContactUpdateContext);
}

export const ContactProvider = ({ children }: any) => {
  const [displayed, setDisplay] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 1100px)");

  useEffect(() => {
    if (isMobile && displayed) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [isMobile, displayed]);

  return (
    <ContactContext.Provider value={displayed}>
      <ContactUpdateContext.Provider value={setDisplay}>
        {children}
      </ContactUpdateContext.Provider>
    </ContactContext.Provider>
  );
};
