import { photoList } from "./photoList";

export interface Photo {
  id: number;
  meta: string;
  filename: string;
  project: string;
  genre: string[];
}

const photos = photoList;

export function getImagesFor(genre: string) {
  return photos.filter((photo) => photo["genre"].includes(genre));
}

export function desktopGalleryImages(genre: string) {
  const filteredPhotos: Photo[] = getImagesFor(genre);
  const length: number = filteredPhotos.length;
  const columnOne: Photo[] = [];
  const columnTwo: Photo[] = [];
  const columnThree: Photo[] = [];
  for (let i: number = 0; i < length; i++) {
    if (i % 3 === 0) {
      columnOne.push(filteredPhotos[i]);
    } else if (i % 3 === 1) {
      columnTwo.push(filteredPhotos[i]);
    } else if (i % 3 === 2) {
      columnThree.push(filteredPhotos[i]);
    }
  }
  return [columnOne, columnTwo, columnThree];
}

export function mobileGalleryImages(genre: string) {
  const filteredPhotos: Photo[] = getImagesFor(genre);
  const columnSize: number = filteredPhotos.length / 3;
  return [
    filteredPhotos.slice(0, -2 * columnSize),
    filteredPhotos.slice(-2 * columnSize, -columnSize),
    filteredPhotos.slice(-columnSize),
  ];
}

export function getImageById(id: any) {
  return photos[photos.findIndex((element) => element.id === parseInt(id))];
}

export function rtrim(x: any, characters: string) {
  var end: number = x.length - 1;
  while (characters.indexOf(x[end]) >= 0) {
    end -= 1;
  }
  return x.substr(0, end + 1);
}
