import React, { useState } from "react";
import { MdMail } from "@react-icons/all-files/md/MdMail";
import { MdContentCopy } from "@react-icons/all-files/md/MdContentCopy";

const EmailWrapper = () => {
  const [copyNotification, showNotification] = useState<boolean>(false);

  function copyToClipboard() {
    navigator.clipboard.writeText("iam@michaldang.com").then(function () {
      showNotification(true);
      setTimeout(() => {
        showNotification(false);
      }, 1000);
    });
  }

  return (
    <div className={"emailContact"} onClick={() => copyToClipboard()}>
      <MdContentCopy />
      {copyNotification ? (
        <h1>Copied</h1>
      ) : (
        <h1>
          <MdMail />
          iam @michaldang.com
        </h1>
      )}
    </div>
  );
};

export default EmailWrapper;
