import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const MenuDesktop = () => {
  const url = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string>(
    url.pathname.substring(1)
  );

  useEffect(() => {
    setCurrentLocation(url.pathname.substring(1));
    var scrollElm: any = document.scrollingElement;
    scrollElm.scrollTop = 0;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [url]);

  return (
    <nav className={"navbarWrapperDesktop"}>
      <Link to={"/"}>
        <h1>MICHAL DANG</h1>
      </Link>
      <ul className={"menuDesktop"}>
        <li>
          <Link to={"/lifestyle"}>
            <p>Lifestyle</p>
            {currentLocation === "lifestyle" && (
              <motion.div className={"underlineMenu"} layoutId={"activeMenu"} />
            )}
          </Link>
        </li>
        <li>
          <Link to={"/portraits"}>
            <p>Portraits</p>
            {currentLocation === "portraits" && (
              <motion.div className={"underlineMenu"} layoutId={"activeMenu"} />
            )}
          </Link>
        </li>
        <li>
          <Link to={"/events"}>
            <p>Events</p>
            {currentLocation === "events" && (
              <motion.div className={"underlineMenu"} layoutId={"activeMenu"} />
            )}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuDesktop;
