import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MobileScrollDown from "../../components/MobileScrollDown/MobileScrollDown";
import useMediaQuery from "../../utils/responsiveHook";
import GalleryImage from "../../components/GalleryImage/GalleryImage";
import {
  desktopGalleryImages,
  mobileGalleryImages,
  rtrim,
} from "../../utils/photos";

const Gallery = () => {
  let imageUrl;
  const BASE_URL = process.env.REACT_APP_PHOTO_BUCKET;
  const url = useLocation();
  const [genre, setGenre] = useState<any>(url.pathname.substring(1));
  const isMobile = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    setGenre(url.pathname.substring(1));
  }, [url]);

  if (genre === "events") {
    imageUrl = BASE_URL + "/large/DSC04350.webp";
  } else if (genre === "portraits") {
    imageUrl = BASE_URL + "/large/DSC09981.webp";
  } else if (genre === "lifestyle") {
    imageUrl = BASE_URL + "/large/MD_03304.webp";
  }

  const [columnOne, columnTwo, columnThree] = isMobile
    ? mobileGalleryImages(genre)
    : desktopGalleryImages(genre);

  return (
    <>
      <div className={"galleryCover noselect"}>
        <p>A collection of my</p>
        <h1>{rtrim(genre, "s")}</h1>
        <p>work</p>
        <LazyLoadImage src={imageUrl} alt={"cover for portfolio"} />
        {isMobile && <MobileScrollDown />}
      </div>
      <div className={"galleryImages"}>
        <div className={"galleryGrid"}>
          <div className={"galleryColumn"}>
            {columnOne.map((element: any) => (
              <GalleryImage image={element} key={element.id} />
            ))}
          </div>
          <div className={"galleryColumn"}>
            {columnTwo.map((element: any) => (
              <GalleryImage image={element} key={element.id} />
            ))}
          </div>
          <div className={"galleryColumn"}>
            {columnThree.map((element: any) => (
              <GalleryImage image={element} key={element.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
