import React, { useEffect, useState } from "react";
import "./MobileScrollDown.css";
import { MdArrowDownward } from "@react-icons/all-files/md/MdArrowDownward";
import { AnimatePresence, motion } from "framer-motion";

const MobileScrollDown = () => {
  const arrowAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  // State of the button's visibility
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const toggleScrollButtonVisibility = () => {
      const scrolled = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      return scrolled >= windowHeight / 3
        ? setIsVisible(false)
        : setIsVisible(true);
    };

    window.addEventListener("scroll", toggleScrollButtonVisibility);
    return () =>
      window.removeEventListener("scroll", toggleScrollButtonVisibility);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={"mobileScrollLanding"}
          variants={arrowAnimation}
          animate={"animate"}
          initial={"initial"}
          exit={"exit"}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: "smooth",
            });
          }}
        >
          <MdArrowDownward />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileScrollDown;
