import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useMediaQuery from "../../utils/responsiveHook";
import { rtrim } from "../../utils/photos";

function PortfolioLink({ image, text, link }: any) {
  const [hovered, setHovered] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <div
      className={"portfolioLink"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link to={link}>
        <motion.img
          src={image}
          animate={
            hovered ? { transform: "scale(1.02)" } : { transform: "scale(1)" }
          }
          alt={text}
        />
        <motion.div
          className={"portfolioLinkOverlay"}
          animate={hovered && !isMobile ? { opacity: 1 } : { opacity: 0 }}
        >
          <p>A collection of my</p>
          <h1>{rtrim(text, "s")}</h1>
          <p>work</p>
        </motion.div>
        {isMobile && (
          <motion.div
            className={"mobileOverlay"}
            initial={{ opacity: 0, transform: "scale(0.95)" }}
            whileInView={{ opacity: 1, transform: "scale(1)" }}
            transition={{ duration: 0.5 }}
          >
            <p>A collection of my</p>
            <h1>{rtrim(text, "s")}</h1>
            <p>work</p>
          </motion.div>
        )}
      </Link>
    </div>
  );
}

export default PortfolioLink;
