// @flow
import * as React from "react";
import "./Navbar.css";
import useMediaQuery from "../../utils/responsiveHook";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";

export const Navbar = () => {
  const isMobile = useMediaQuery("(max-width: 1200px)");
  return isMobile ? <MenuMobile /> : <MenuDesktop />;
};
