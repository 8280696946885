import React from "react";
import "./Contact.css";
import turningWheel from "./../../assets/logo.webp";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { motion, AnimatePresence } from "framer-motion";
import ContactForm from "./ContactForm";
import useMediaQuery from "../../utils/responsiveHook";
import { useContact, useContactUpdate } from "../../utils/contactProvider";

function Contact() {
  const displayed: any = useContact();
  const setDisplay: any = useContactUpdate();
  const isMobile = useMediaQuery("(max-width: 1200px)");

  function toggleDisplay() {
    setDisplay((state: boolean) => !state);
  }

  const buttonVariant = {
    hidden: {
      right: "70px",
    },
    visible: {
      right: displayed ? "730px" : "70px",
    },
  };

  const buttonVariantMobile = {
    hidden: {
      right: "30px",
    },
    visible: {
      right: displayed ? "700px" : "30px",
    },
  };

  const rotateRef = React.useRef<any>(null);

  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      if (rotateRef) {
        rotateRef.current.style.transform = `rotate(${window.scrollY / 10}deg)`;
        rotateRef.current.style.webkitTransform = `-webkit-rotate(${
          window.scrollY / 10
        }deg)`;
      }
    });
  });

  return (
    <>
      <motion.div
        className={"scrollButtonWrapper noselect"}
        variants={isMobile ? buttonVariantMobile : buttonVariant}
        animate={"visible"}
        initial={"hidden"}
        exit={"hidden"}
      >
        <div ref={rotateRef}>
          <img src={turningWheel} alt={"contact me"} />
        </div>

        <button
          className={"contactMeButton"}
          onClick={() => toggleDisplay()}
          aria-label={"openContact"}
        >
          {displayed ? (
            <MdClose color={"white"} />
          ) : (
            <AiOutlineMail color={"white"} />
          )}
        </button>
      </motion.div>
      <AnimatePresence>
        {displayed && <ContactForm closeModal={toggleDisplay} />}
      </AnimatePresence>
    </>
  );
}

export default Contact;
