import React from "react";
import "./PhotoDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { getImageById, getImagesFor } from "../../utils/photos";
import { useEventListener } from "../../utils/eventListenerHook";
import { GrFormNext } from "@react-icons/all-files/gr/GrFormNext";
import { GrFormPrevious } from "@react-icons/all-files/gr/GrFormPrevious";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PhotoDetail = () => {
  const { id } = useParams();
  const displayImage = getImageById(id);
  let navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_PHOTO_BUCKET;

  console.log(BASE_URL)

  function navigateToNextImage() {
    const filteredPhotos = getImagesFor(displayImage.genre[0]);
    const indexOfImage = filteredPhotos.findIndex(
      (photo) => photo.id === displayImage.id
    );

    if (indexOfImage === filteredPhotos.length - 1) return;

    const newId = filteredPhotos[indexOfImage + 1].id;
    navigate(`../../photo/${newId}`);
  }

  function navigateToPreviousImage() {
    const filteredPhotos = getImagesFor(displayImage.genre[0]);
    const indexOfImage = filteredPhotos.findIndex(
      (photo) => photo.id === displayImage.id
    );

    if (indexOfImage === 0) return;

    const newId = filteredPhotos[indexOfImage - 1].id;
    navigate(`../../photo/${newId}`);
  }

  function canMoveForwardAndBackwards() {
    const filteredPhotos = getImagesFor(displayImage.genre[0]);
    const indexOfImage = filteredPhotos.findIndex(
      (photo) => photo.id === displayImage.id
    );
    var forDisplay = indexOfImage !== filteredPhotos.length - 1;
    var backDisplay = indexOfImage !== 0;
    return [forDisplay, backDisplay];
  }

  const [forward, backward] = canMoveForwardAndBackwards();

  const handler = ({ key }: any) => {
    switch (key) {
      case "ArrowRight":
        navigateToNextImage();
        break;
      case "ArrowLeft":
        navigateToPreviousImage();
        break;
    }
  };
  useEventListener("keydown", handler);

  const animateVariants = {
    hidden: { opacity: 0, transition: { duration: 1 } },
    visible: { opacity: 1, transition: { duration: 1 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  return (
    <>
      <div className={"photoPageWrapper"}>
        {backward && (
          <div
            className={"photoControlBackward"}
            onClick={() => navigateToPreviousImage()}
          >
            <GrFormPrevious />
          </div>
        )}

        {forward && (
          <div
            className={"photoControlForward"}
            onClick={() => navigateToNextImage()}
          >
            <GrFormNext />
          </div>
        )}
        <div className={"photoWrapper noselect"}>
          <LazyLoadImage
            src={`${BASE_URL}/large/${displayImage.filename}.webp`}
            alt={displayImage.meta}
          />
        </div>
      </div>
    </>
  );
};

export default PhotoDetail;
